import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import { useAppData } from "../../providers/AppDataProvider";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import bgvideo from "../../assets/video/bgvideo.mp4";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { strings, modalOpen, focus, setFocus, setModalOpen } = useAppData();
  const [cookiePopup, setCookiePopup] = useState(true);
  useEffect(() => {
    setFocus(true);
    if (width > 485) {
      document.getElementById("video").play();
    }
  }, []);
  useEffect(() => {
    if (!modalOpen) {
      window.scrollTo(0, 0);
    }
  }, [modalOpen]);

  const renderCookiePopup = () => {
    return (
      <div
        className={styles.cookieOuterContainerStyle}
        style={{ height: `${window.innerHeight}px` }}
      >
        <div className={styles.cookieContainerStyle}>
          <div className={styles.cookieInsideContainerStyle}>
            <p className={styles.cookieTextStyle}>
              At Rosholmdell.se we use cookies for visitor statistics, user
              experience and user security. Apart from the Cookies that are
              necessary for the Website to function, you can choose which
              Cookies you want to allow and not by clicking on "Settings". You
              can change your settings or withdraw your consent at any time by
              clicking on the "Cookie consent" tab at the bottom of the page.
              For more information about Cookies and how we handle your personal
              data, see our&nbsp;
              <span
                className={styles.linkTextStyle}
                onClick={() => navigate("/cookienotice")}
              >
                Cookie Notice
              </span>
              &nbsp;and our&nbsp;
              <span
                className={styles.linkTextStyle}
                onClick={() => navigate("/privacynotice")}
              >
                Privacy Notice
              </span>
            </p>
            <div className={styles.cookieBtnContainerStyle}>
              <Button
                buttonName={"SETTINGS"}
                btnStyle={styles.cookieBtnSettingStyles}
              />
              <Button
                buttonName={width > 485 ? "AGREE & CLOSE" : "AGREE"}
                onClick={() => setCookiePopup(false)}
                btnStyle={styles.cookieBtnAgreeStyles}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={
        focus
          ? styles.homePageFocusContainerStyle
          : styles.homePageBlurContainerStyle
      }
    >
      <Helmet>
        <meta
          name="description"
          content="Upptäck användbara juridiska tjänster hos RosholmDell. Expertråd för dina behov. Läs mer nu."
        />
        <meta name="keywords" content="Useful Legal Services" />
        <link rel="canonical" href="https://rosholmdell.se/" />
        <title>RosholmDell Advokatfirma | Expert juridiska tjänster</title>
      </Helmet>
      <Navbar />

      <div
        id="home"
        className={
          modalOpen
            ? styles.homePageMainDownContainerStyle
            : styles.homePageMainContainerStyle
        }
        style={{ height: `${window.innerHeight}px` }}
      >
        {width > 485 && (
          <video
            id="video"
            className={styles.bgVideoStyle}
            muted
            autoPlay
            loop={true}
          >
            <source src={bgvideo} type="video/mp4" />
          </video>
        )}
        <div className={styles.homePageMainInsideContainerStyle}>
          <div className={styles.heroHeaderContainerStyle}>
            <div className={styles.heroHeaderTextContainerStyle}>
              <h1
                // onClick={() => navigate("/about")}
                className={styles.heroHeaderTextStyle}
              >
                {strings.homePage.headerText}
              </h1>
            </div>
            <Button
              buttonName={strings.homePage.buttonText}
              arrowBtnStyle={styles.arrowButtonStyle}
              btnStyle={styles.btnHoverStyles}
              arrowBtn={true}
              onClick={() => setModalOpen(!modalOpen)}
            />
          </div>
        </div>
      </div>
      {/* {cookiePopup && <> {renderCookiePopup()}</>} */}
    </div>
  );
};

export default HomePage;
